import {
  FormInput,
  FormLabel,
  FormSelect,
  FormTextarea,
} from "../base-components/Form";

const CustomFormInput = ({
  label,
  name,
  type,
  InputType,
  value,
  onChange,
  placeholder,
  disabled = false,
  threeCol = false,
  rows = 3,
  required,
}: any) => {
  return (
    <div
      className={`w-full  ${
        InputType === "text-area"
          ? "col-span-12"
          : threeCol
          ? "col-span-12 md:col-span-4 lg:col-span-3"
          : "col-span-12 md:col-span-6 lg:col-span-4"
      }`}
    >
      {/* <FormLabel className="text-gray-500" htmlFor={name}>
        {label}
      </FormLabel> */}
      {label && (
        <FormLabel className="text-gray-500 flex items-center" htmlFor={name}>
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}{" "}
          {/* Red asterisk for required fields */}
        </FormLabel>
      )}
      {InputType === "text-area" ? (
        <FormTextarea
          id={name}
          name={name}
          rows={rows}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <FormInput
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          className={`w-full p-2 border`  }       />
      )}
    </div>
  );
};

export default CustomFormInput;
