import React, { useEffect, useState } from "react";
import { Typography, Card } from "antd";
import axios from "axios";
import { NavLinkProps, useLocation } from "react-router-dom";
// import HeaderFooterLayout from "../../Layout";
// import HotelHero from "../../Components/Hero/HotelHero";

const { Title, Paragraph } = Typography;

const TermsAndConditions = () => {
  const location = new URLSearchParams(useLocation().search);

  const service_type = location.get("service_type");
  const policy_type = location.get("policy_type");
  const customer_type = location.get("customer_type");

  const [meta, setMeta] = useState<{
    heading: string;
    content: string;
    isData: boolean;
    id?: any;
  }>({
    heading: "",
    content: "",
    isData: false,
  });

  useEffect(() => {
    const fetchPolicy = async () => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/admin/policies?service_type=${service_type}&policy_type=${policy_type}&customer_type=${customer_type}`;

      console.log("URL", url);
      try {
        const response = await axios.get(url);
        console.log("response", response);
        if (response.data && response.data.data) {
          const data = response.data.data[0];
          setMeta({ ...data, isData: true });
        }
      } catch (error) {
        console.log("Error fetching privacy and policy:", error);
      }
    };

    fetchPolicy();
  }, []);

  return (
    <div className="container mx-auto py-8 px-4">
      <Card>
        {meta.isData ? (
          <>
            <Title level={2} className="text-center">
              {meta.heading}
            </Title>
            <Paragraph className="text-lg">{meta.content}</Paragraph>
          </>
        ) : (
          <Title level={2} className="text-center">
            No terms & conditions added
          </Title>
        )}
      </Card>
    </div>
  );
};

export default TermsAndConditions;
