import React from "react";

export const MainHeading = ({ title }) => {
  return (
    <div className="title mt-3 w-full col-span-6 md:col-span-6">
      <div className="text-2xl text-gray-600 font-bold text-[#0064B9]">{title}</div>
      <div className="mutted w-full text-xs text-mutted text-gray-400">
        Please fill in the required fields.
      </div>
    </div>
  );
};

const Layout = ({ title, children }) => {
  return (
    <>
      <MainHeading title={title} />
      <div className="col-span-6 p-4  w-full grid grid-cols-6 gap-3 border-blue-500 border-2 rounded-lg shadow-sm">
        {children}
      </div>
    </>
  );
};

export default Layout;
