import React from "react";

import CustomFormInput from "../../../custom-components/CustomFormInput"; // Adjust the path as necessary
import PhoneInputComponent from "../../../custom-components/PhoneInputComponent";
import Layout from "./Layout";
// import PhoneInputComponent from "../../../custom-components/PhoneInputComponent";

const BankDetails = ({ meta, onMetaChange, isViewPage }) => {
  return (
    <Layout title="Bank Details">
      <div className="col-span-4 md:col-span-2">
        <CustomFormInput
          label="Account Holder Name"
          name="accountName"
          type="text"
          value={meta?.bank_details?.accountName || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          placeholder="Enter account name"
          required
        />
      </div>

      <div className="col-span-4 md:col-span-2">
        <CustomFormInput
          label="Bank Name"
          name="bankName"
          type="text"
          value={meta?.bank_details?.bankName || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          placeholder="Enter bank name"
          required
        />
      </div>

      <div className="col-span-4 md:col-span-2">
        <CustomFormInput
          label="Account Number"
          name="accountNumber"
          type="text"
          value={meta?.bank_details?.accountNumber || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          placeholder="Enter account number"
          required
        />
      </div>

      <div className="col-span-4 md:col-span-2">
        <CustomFormInput
          label="IBAN"
          name="iban"
          type="text"
          value={meta?.bank_details?.iban || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          placeholder="Enter IBAN"
          required
        />
      </div>

      <div className="col-span-4 md:col-span-2">
        <CustomFormInput
          label="Billing Address"
          name="billingAddress"
          type="text"
          value={meta?.bank_details?.billingAddress || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          placeholder="Enter billing address"
          required
        />
      </div>

      <div className="col-span-4 md:col-span-2">
        {/* <PhoneInputComponent
          label="Phone Number"
          name="phoneNumberBank"
          value={meta?.bank_details?.phoneNumberBank || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          placeholder="Enter phone number"
          required
        /> */}
        <CustomFormInput
          label="Phone Number"
          name="phoneNumberBank"
          type="text"
          value={meta?.bank_details?.phoneNumberBank || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          placeholder="Enter phone number"
          required
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <CustomFormInput
          label="Swift Code"
          name="swift_code"
          type="text"
          value={meta?.bank_details?.swift_code || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          placeholder="Enter Swift Code"
          required
        />
      </div>
      {/* <div className="col-span-4 md:col-span-2">
        <CustomFormInput
          label="Dyfana Comission"
          name="admin_comission"
          type="text"
          value={meta?.bank_details?.admin_comission || ""}
          onChange={(e) => onMetaChange(e, "bank_details")}
          disabled={isViewPage}
          //   placeholder="Enter Commission"
          required
        />
      </div> */}
    </Layout>
  );
};

export default BankDetails;
