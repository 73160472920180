import { FormInput, FormLabel, FormSelect } from "../../base-components/Form";
import Button from "../../base-components/Button";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layouts/Auth";
import logoUrl from "../../assets/images/logo.png";
import { ChangeEvent, useEffect, useState } from "react";
import Loader from "../../base-components/Loader";
import toast from "react-hot-toast";
import axios, { AxiosError, AxiosResponse } from "axios";
import Loading from "../../components/Loading";
import { validateNumberNotNegative } from "../../utils/helper";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Select } from "antd";

interface SignUpMeta {
  c_email: string;
  email: string;
  registeration_number: number;
  phonenumber: number | string;
  website_link: string;
  password: string;
  type: string;
  commission_type: string;
  commission: number;
  // id_card: string;
  // business_license: string;
  username: string;
  business_owner_id_front: string;
  business_owner_id_back: string;
  business_license_front: string;
  business_license_back: string;
}

interface ErrorResponseData {
  error?: string;
}

function Main() {
  const [isSigningUp, setIsSigningUp] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [idCard, setIDCard] = useState<string | null>(null);
  const [businessLicense, setBusinessLicense] = useState<string | null>(null);
  const [meta, setMeta] = useState<SignUpMeta>({
    c_email: "",
    email: "",
    registeration_number: 0,
    phonenumber: "",
    website_link: "",
    password: "",
    type: "",
    commission_type: "Fixed",
    commission: 0,
    // id_card: "",
    // business_license: "",
    username: "",
    business_owner_id_front: "",
    business_owner_id_back: "",
    business_license_front: "",
    business_license_back: "",
  });

  console.log("meta", meta);

  const onSignUpMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => {
    const { name, value, type } = e.target;

    const nonNegativeFields = [
      // "registeration_number",
      "phonenumber",
      "commission",
    ];
    if (nonNegativeFields.includes(name)) {
      console.log("name", name);
      return validateNumberNotNegative(type, value, setMeta, name);
    }

    // console.log("value", Number(value));

    setMeta({
      ...meta,
      [name]: value,
    });
  };

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const dataURL = reader.result;
        console.log("dataurl", dataURL);
        onSignUpMetaChange({ target: { name, value: dataURL } });
      };
    }
  };

  // useEffect(() => {
  //   setMeta({
  //     ...meta,
  //     id_card: idCard || "",
  //   });
  // }, [idCard]);

  // useEffect(() => {
  //   setMeta({
  //     ...meta,
  //     business_license: businessLicense || "",
  //   });
  // }, [businessLicense]);

  const navigate = useNavigate();

  const onSignUp = () => {
    // if (!isSigningUp) {
    setIsSigningUp(true);

    console.log(
      `URL : ${process.env.REACT_APP_BACKEND_URL}/transport/register`
    );
    console.log("meta", meta);

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/transport/register`, meta, {
        withCredentials: true,
      })
      .then((response: AxiosResponse) => {
        console.log("response", response);
        if (response.status === 200) {
          toast.success(
            "User Registered Successfully. Your account is on approval wait for our confirmation"
          );

          localStorage.setItem("doyoofToken", "Bearer " + response.data.token);

          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error(response.data?.error);
        }
        setIsSigningUp(false);
      })
      .catch((error: AxiosError<ErrorResponseData>) => {
        console.log("error", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("An unexpected error occurred.");
        }
        setIsSigningUp(false);
      });
    // }
  };

  const handleFileChangeIDCard = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result as string;
        setIDCard(dataURL);
      };
    }
  };

  const handleFileChangeBusinessLicense = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result as string;
        setBusinessLicense(dataURL);
      };
    }
  };

  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <>
      {loading && <Loading />}
      <AuthLayout>
        <div className="flex flex-col items-center h-screen py-5 my-4 xl:h-auto xl:py-0 xl:my-0 gap-12 md:gap-6">
          <img
            alt="Midone Tailwind HTML Admin Template"
            src={logoUrl}
            className="xl:hidden w-5/12 md:w-48 cursor-pointer"
          />
          <div className="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 className="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">
              Register Your Company With Dyfana
            </h2>
            <div className="mt-4 intro-x max-h-[70vh] overflow-y-auto px-5">
              <FormLabel className="text-gray-600" htmlFor="username">
                Username <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Username"
                name="username"
                value={meta.username}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="email">
                Email <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Email"
                name="email"
                value={meta.email}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="c_email">
                Company Email <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Company Email"
                name="c_email"
                value={meta.c_email}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="phonenumber">
                Phone Number <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Phone Number"
                name="phonenumber"
                value={meta.phonenumber}
                onChange={onSignUpMetaChange}
              />
              <FormLabel
                className="text-gray-600 mt-3"
                htmlFor="registeration_number"
              >
                Business Registration Number{" "}
                <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Business Registration Number"
                name="registeration_number"
                value={meta.registeration_number}
                min={0}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="website_link">
                Website Link <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Website Link"
                name="website_link"
                value={meta.website_link}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="password">
                Password <span className="text-red-500 ">*</span>
              </FormLabel>
              <div className="flex items-center relative">
                <FormInput
                  type={visible ? "text" : "password"}
                  className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px] pr-10"
                  placeholder="Password"
                  name="password"
                  value={meta.password}
                  onChange={onSignUpMetaChange}
                />
                <div
                  onClick={toggleVisibility}
                  className="absolute right-4 bottom-3 cursor-pointer"
                >
                  {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              </div>

              <div className="col-span-6 md:col-span-2">
                <FormLabel
                  className="text-gray-500 mt-3 flex items-center"
                  htmlFor="commission_type"
                >
                  Commission Type
                  <span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormSelect
                  id="commission_type"
                  name="commission_type"
                  value={meta.commission_type}
                  onChange={onSignUpMetaChange}
                >
                  <option value="Percentage">Percentage</option>
                  <option value="Fixed">Fixed</option>
                </FormSelect>
              </div>

              <FormLabel className="text-gray-600 mt-3" htmlFor="commission">
                How much Commission You will give to us?{" "}
                <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="number"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Commission"
                name="commission"
                value={meta.commission}
                onChange={onSignUpMetaChange}
              />

              {/* ------------------------------------ */}
              <div className="flex flex-col">
                <FormLabel
                  className="text-gray-600 mt-3"
                  htmlFor="business_owner_id_front"
                >
                  Upload ID card of Business Owner (Front & Back Image)
                  <span className="text-red-500 ">*</span>
                </FormLabel>
                <div className="">
                  <FormInput
                    type="file"
                    id="business_owner_id_front"
                    className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                    name="business_owner_id_front"
                    // onChange={handleFileChangeIDCard}
                    onChange={(e) => handleFileChange(e, "business_owner_id_front")}
                  />
                  {/* <FormLabel className="text-gray-600 mt-3" htmlFor="business_owner_id_back">
                Upload ID card of Business Owner (Bakc Image)
                <span className="text-red-500 ">*</span>
              </FormLabel> */}
                  <FormInput
                    type="file"
                    id="business_owner_id_back"
                    className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                    name="business_owner_id_back"
                    // onChange={handleFileChangeIDCard}
                    onChange={(e) => handleFileChange(e, "business_owner_id_back")}
                  />
                </div>
              </div>
              {/* -------------------------- */}
              <div className="flex flex-col">
                <FormLabel
                  className="text-gray-600 mt-3"
                  htmlFor="business_license_front"
                >
                  Upload Scanned Copy of Business License Front
                  <span className="text-red-500 ">*</span>
                </FormLabel>
                <div>
                  {" "}
                  <FormInput
                    type="file"
                    id="business_license_front"
                    className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                    name="business_license_front"
                    // onChange={handleFileChangeBusinessLicense}
                    onChange={(e) => handleFileChange(e, "business_license_front")}

                  />
                  <FormInput
                    type="file"
                    id="business_license_back"
                    className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                    name="business_license_back"
                    // onChange={handleFileChangeBusinessLicense}
                    onChange={(e) => handleFileChange(e, "business_license_back")}

                  />
                </div>
              </div>

              {/* -------------------------- */}
              {/* <FormLabel className="text-gray-600 mt-3" htmlFor="id_card">
                Upload ID card of Business Owner{" "}
                <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="file"
                id="id_card"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                name="id_card"
                onChange={handleFileChangeIDCard}
              />
              <FormLabel
                className="text-gray-600 mt-3"
                htmlFor="business_license"
              >
                Upload Scanned Copy of Business License{" "}
                <span className="text-red-500 ">*</span>
              </FormLabel>
              <FormInput
                type="file"
                id="business_license"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                name="business_license"
                onChange={handleFileChangeBusinessLicense}
              /> */}
              <FormLabel htmlFor="type" className="mr-2 mt-2">
                Register as a
              </FormLabel>
              <div className="flex flex-col items-start gap-2">
                <div>
                  <input
                    type="radio"
                    id="transport"
                    name="type"
                    value="Transport"
                    checked={meta.type === "Transport"}
                    onChange={onSignUpMetaChange}
                  />
                  <label htmlFor="transport" className="ml-1 mr-3">
                    Transport Company
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="carrental"
                    name="type"
                    value="CarRental"
                    checked={meta.type === "CarRental"}
                    onChange={onSignUpMetaChange}
                  />
                  <label htmlFor="carrental" className="ml-1">
                    Car Rental Company
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center intro-x xl:mt-8 xl:text-left">
              <Button
                onClick={onSignUp}
                variant="primary"
                disabled={isSigningUp}
                className="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
              >
                {isSigningUp ? (
                  <Loader className="w-5 h-5 text-white" />
                ) : (
                  "Register"
                )}
              </Button>
              <Button
                onClick={() => navigate("/login")}
                variant="outline-secondary"
                className="w-full px-4 py-3 mt-3 align-top xl:w-32 xl:mt-0"
              >
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default Main;
