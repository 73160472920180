import { FormInput } from "../../base-components/Form";
import Button from "../../base-components/Button";
import AuthLayout from "../../layouts/Auth";
import { NavLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import logoUrl from "../../assets/images/logo.png";
import { ChangeEvent, useState } from "react";
import Loader from "../../base-components/Loader";
import axios, { AxiosError, AxiosResponse } from "axios";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

interface SignInMeta {
  email: string;
  password: string;
}

interface ErrorResponseData {
  error?: string;
}

function Main() {
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

  const [meta, setMeta] = useState<SignInMeta>({
    email: "",
    password: "",
  });

  const onSignInMetaChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setMeta({
      ...meta,
      [name]: value,
    });
  };

  const naviagte = useNavigate();

  const onSignIn = () => {
    if (!isSigningIn) {
      if (meta.email === "") {
        toast.error("Email is required");
      } else if (meta.password === "") {
        toast.error("Password is required");
      } else {
        setIsSigningIn(true);
        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/transport/login`, meta, {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response: AxiosResponse) => {
            if (response.status === 200) {
              toast.success("Login successfully");

              localStorage.setItem(
                "doyoofToken",
                "Bearer " + response.data.token
              );

              setTimeout(() => {
                naviagte("/");
                window.location.reload();
              }, 2000);
            } else {
              toast.error(response.data?.error);
            }
            setIsSigningIn(false);
          })
          .catch((error: AxiosError<ErrorResponseData>) => {
            console.log("login error", error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              toast.error(error.response.data.error);
            } else {
              toast.error(
                "An unexpected error occurred. Please try again later."
              );
            }
            setIsSigningIn(false);
          });
      }
    }
  };

  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <>
      <AuthLayout>
        <div className="flex flex-col items-center h-screen py-5 my-4 xl:h-auto xl:py-0 xl:my-0 gap-12 md:gap-6">
          <img
            alt="Midone Tailwind HTML Admin Template"
            src={logoUrl}
            className="xl:hidden w-5/12 md:w-48 cursor-pointer"
          />
          <div className="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 className="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">
              Sign In
            </h2>
            <div className="mt-2 text-center intro-x text-slate-400 xl:hidden">
              A few more clicks to sign in to your account.
            </div>
            <div className="mt-8 intro-x">
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Email"
                value={meta.email}
                name="email"
                onChange={(e) => onSignInMetaChange(e)}
              />
              <div className="flex items-center relative">
                <FormInput
                  type={visible ? "text" : "password"}
                  // className="block px-4 py-3 mt-4 intro-x min-w-full xl:min-w-[350px]"
                  className="block px-4 py-3 mt-4 intro-x min-w-full xl:min-w-[350px] pr-10"
                  placeholder="Password"
                  value={meta.password}
                  name="password"
                  onChange={(e) => onSignInMetaChange(e)}
                />
                <div
                  onClick={toggleVisibility}
                  className="absolute right-4 bottom-3 cursor-pointer"
                >
                  {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              </div>
            </div>
            <div className="mt-5 text-center intro-x xl:mt-8 xl:text-left">
              <Button
                onClick={() => onSignIn()}
                variant="primary"
                disabled={isSigningIn}
                className="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
              >
                {isSigningIn ? (
                  <Loader className={"w-5 h-5 text-white"} />
                ) : (
                  "Login"
                )}
              </Button>
              <Button
                onClick={() => naviagte("/register")}
                variant="outline-secondary"
                className="w-full px-4 py-3 mt-3 align-top xl:w-32 xl:mt-0"
              >
                Register
              </Button>
            </div>
            <div className="mt-10 text-center intro-x xl:mt-24 text-slate-600 dark:text-slate-500 xl:text-left">
              By signin, you agree to our
              <NavLink
                className="text-primary dark:text-slate-200"
                to={`/terms-and-conditions?service_type=transport&policy_type=terms_and_conditions&customer_type=b2b`}
              >
                Terms and Conditions
              </NavLink>
              &
              <NavLink
                className="text-primary dark:text-slate-200"
                to={`/privacy-policy?service_type=transport&policy_type=privacy_policy&customer_type=b2b`}
              >
                Privacy Policy
              </NavLink>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default Main;
