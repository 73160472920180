import DarkModeSwitcher from "../../components/DarkModeSwitcher";
import logoUrl from "../../assets/images/logo.png";
import illustrationUrl from "../../assets/images/illustration.svg";
import clsx from "clsx";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const isDarkMode = useSelector((state: RootState) => state.darkMode.value);
  
  return (
    <>
      <div
        className={clsx([
          "-m-3 sm:-mx-8 p-3 sm:px-8 relative h-screen lg:overflow-hidden bg-primary xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600",
          "before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg] before:bg-primary/20 before:rounded-[100%] before:dark:bg-darkmode-400",
          "after:hidden after:xl:block after:content-[''] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-primary after:rounded-[100%] after:dark:bg-darkmode-700",
        ])}
      >
        {/* <DarkModeSwitcher /> */}
        <div className="container relative z-10 sm:px-10">
          <div className="block grid-cols-2 gap-4 xl:grid">
            {/* BEGIN: Login Info */}
            <div className="flex-col hidden min-h-screen xl:flex">
              <span className="flex items-center pt-5 ">
                <img alt="logo" src={logoUrl}  className="w-80 xl:w-80" />
              </span>
              <div className="my-20">
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  className="w-1/2 -mt-16 -intro-x"
                  src={illustrationUrl}
                />
                <div className="mt-10 text-4xl font-medium leading-tight text-white -intro-x">
                  A few more clicks to <br />
                  sign in to your account.
                </div>
                <div className="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-slate-400">
                  Manage all your Dyfana accounts in one place
                </div>
              </div>
            </div>
            {/* END: Login Info */}
            {/* BEGIN: Login Form */}
            {children}
            {/* END: Login Form */}
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: { 
            backgroundColor: isDarkMode ? "rgb(27 37 59)" : "#fff",
            color: isDarkMode ? "#fff" : "black"
           }
        }}
      />
    </>
  );
};

export default AuthLayout;
