import { ChangeEvent, useState, useEffect } from "react";
import { PreviewComponent } from "../../base-components/PreviewComponent";
import {
  FormInput,
  FormLabel,
  FormSelect,
  FormTextarea,
} from "../../base-components/Form";
import toast from "react-hot-toast";
import Button from "../../base-components/Button";
import Loading from "../../components/Loading";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import AdditionalPrices, {
  addAdditionalPrice,
} from "./Component/AdditionalPrices";
import { validateNumberNotNegative } from "../../utils/helper";
import BankDetails from "./Component/BankDetails";
import { vehicleTypes } from "./vehicleTypes";
import ImageZoom from "../../base-components/ImageZoom";

const EditTransport = () => {
  const navigate = useNavigate();
  const [isAddingTransportType, setIsAddingTransportType] =
    useState<boolean>(false);

  const location = useLocation();
  const { state, pathname } = location;
  const isViewPage = pathname.includes("view-transport");

  const [loading, setLoading] = useState<boolean>(false);
  console.log("state", state);
  const [meta, setMeta] = useState(state);
  const [step, setStep] = useState<number>(0);
  const totalSteps = 2;

  const onMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    field?: string
  ) => {
    const { name, value, type } = e.target;

    const nonNegativeFields = [
      "price_per_km",
      "people",
      "bags",
      // "seats",
      "doors",
    ];
    if (nonNegativeFields.includes(name)) {
      console.log("name", name);
      return validateNumberNotNegative(type, value, setMeta, name);
    }

    if (field) {
      console.log("field", field);
      return setMeta((prevMeta) => {
        return {
          ...prevMeta,
          [field]: {
            ...(prevMeta[field] || {}),
            [name]: value,
          },
        };
      });
    }

    setMeta((prevMeta) => ({
      ...prevMeta,
      [name]: value === "true" ? true : value === "false" ? false : value,
    }));
  };

  const stepHandler = (stepDirection = "prev") => {
    const { bank_details } = meta;

    if (stepDirection === "prev" && step > 0) {
      setStep((s) => s - 1);
    }

    if (step === 0) {
      if (!meta.label) {
        return toast.error("Car Type is required");
      } else if (!meta.car_model) {
        return toast.error("Car Name is required");
      } else if (!meta.car_number) {
        return toast.error("Car Registration Number is required");
      } else if (!meta.label) {
        return toast.error("Car Type is required");
      } else if (!meta.description) {
        return toast.error("Description is required");
      } else if (!meta.price_per_km) {
        return toast.error("Price Per Km is required");
      } else if (!meta.people) {
        return toast.error("People must be greater than zero");
      } else if (!meta.bags) {
        return toast.error("Luggage must be greater than zero");
      } else if (!meta.doors) {
        return toast.error("Doors must be greater than zero");
      }
    }

    // if (step === 1) {
    //   // bank details validation...
    // }
    if (stepDirection === "next" && step < totalSteps - 1) {
      setStep((s) => s + 1);
    }
  };

  const onUpdateTransportType = () => {
    const { bank_details } = meta;

    // Validating fields
    if (!bank_details.accountName) {
      return toast.error("Bank account name is required");
    } else if (!bank_details.bankName) {
      return toast.error("Bank name is required");
    } else if (!bank_details.accountNumber) {
      return toast.error("Bank account number is required");
    } else if (!bank_details.iban) {
      return toast.error("IBAN is required");
    } else if (!bank_details.billingAddress) {
      return toast.error("Billing address is required");
    } else if (!bank_details.phoneNumberBank) {
      return toast.error("Bank phone number is required");
    } else if (!bank_details.swift_code) {
      return toast.error("SWIFT code is required");
    } else {
      setIsAddingTransportType(true);
      meta.value = meta.label;

      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/transport-type/${state?.id}`,
          meta
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Transport type updated successfully");
            setIsAddingTransportType(false);
            navigate("/transport-type");
          } else {
            toast.error("Something went wrong.");
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            toast.error("Something went wrong! Please try again later.");
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    }
  };

  const isLastStep = step === totalSteps - 1;

  return (
    <>
      {loading == true && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">
            {/* {isViewPage ? meta.label : "Update transport"} */}
            {meta.label}
          </h1>
          <div className="w-full grid grid-cols-6 gap-4 pt-6">
            {step === 0 || isViewPage ? (
              <>
                <div className="title my-3 w-full col-span-6 md:col-span-6">
                  <div className=" text-2xl text-gray-600 font-bold">
                    Basic Info
                  </div>
                  <div className="mutted w-full text-xs text-mutted text-gray-400">
                    Please fill the required fields
                  </div>
                </div>
                <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
                  {/* <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="hotelName">
                  Label
                </FormLabel>
                <FormInput
                  type="text"
                  id="label"
                  name="label"
                  value={meta.label}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="add label name"
                  disabled={isViewPage}
                />
              </div> */}
                  <div className="col-span-3 md:col-span-2">
                    <FormLabel
                      htmlFor="hotelName"
                      className="text-gray-500 flex items-center"
                    >
                      Car Type
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>

                    <FormSelect
                      id="label"
                      name="label"
                      value={meta.label}
                      onChange={(e) => onMetaChange(e)}
                      disabled={isViewPage}
                    >
                      {vehicleTypes.map((vehicle) => (
                        <option value={vehicle.name}>{vehicle.name}</option>
                      ))}

                      {/* <option value="#">Select Car Type</option>
                  <option value="Mid Size">Mid Size</option>
                  <option value="Large Size">Large Size</option>
                  <option value="Toyota Innova">Toyota Innova</option>
                  <option value="Hundai H1">Hundai H1</option>
                  <option value="GMC Yukon XL">GMC Yukon XL</option>
                  <option value="Coaster">Coaster</option>
                  <option value="Bus">Bus</option> */}
                    </FormSelect>
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <FormLabel
                      htmlFor="car_model"
                      className="text-gray-500 flex items-center"
                    >
                      Car Name
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="text"
                      id="car_model"
                      name="car_model"
                      value={meta.car_model}
                      onChange={(e) => onMetaChange(e)}
                      placeholder="Enter Car Name"
                      disabled={isViewPage}
                    />
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <FormLabel
                      htmlFor="model"
                      className="text-gray-500 flex items-center"
                    >
                      Car Registration Number
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="text"
                      id="car_number"
                      name="car_number"
                      value={meta.car_number}
                      onChange={(e) => onMetaChange(e)}
                      placeholder="Enter Car Registeration Number"
                      disabled={isViewPage}
                    />
                  </div>
                  <div className="col-span-6 md:col-span-3">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="bags"
                    >
                      Luggage
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="number"
                      min={0}
                      id="bags"
                      name="bags"
                      value={meta.bags}
                      onChange={(e) => onMetaChange(e)}
                      placeholder="Luggage capacity"
                      disabled={isViewPage}
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2">
                    <FormLabel className="text-gray-500  " htmlFor="doors">
                      Doors
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="number"
                      id="doors"
                      name="doors"
                      value={meta.doors}
                      onChange={(e) => onMetaChange(e)}
                      placeholder="Doors"
                      disabled={isViewPage}
                    />
                  </div>

                  <div className="col-span-4 md:col-span-2">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="people"
                    >
                      People <span className="text-red-500 ml-1 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="number"
                      id="people"
                      name="people"
                      value={meta.people}
                      onChange={(e) => onMetaChange(e)}
                      placeholder="People"
                      disabled={isViewPage}
                    />
                  </div>

                  <div className="col-span-4 md:col-span-2">
                    <FormLabel className="text-gray-500" htmlFor="price_per_km">
                      Price/km
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="number"
                      id="price_per_km"
                      name="price_per_km"
                      value={meta.price_per_km}
                      onChange={(e) => onMetaChange(e)}
                      placeholder="Price per kilometer"
                      disabled={isViewPage}
                    />
                  </div>
                  {/* <div className="col-span-4 md:col-span-2">
              <FormLabel className="text-gray-500" htmlFor="seats">
              Seats  <span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormInput
                  type="number"
                  id="seats"
                  name="seats"
                  value={meta.seats}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="seats"
                  disabled={isViewPage}
                />
              </div> */}

                  <div className="col-span-6 md:col-span-3">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="ac"
                    >
                      AC <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormSelect
                      id="ac"
                      name="ac"
                      value={meta.ac ? "true" : "false"}
                      onChange={(e) => onMetaChange(e)}
                      disabled={isViewPage}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </FormSelect>
                  </div>

                  <div className="col-span-6 md:col-span-3">
                    <FormLabel className="text-gray-500" htmlFor="auto">
                      Transmission
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormSelect
                      id="auto"
                      name="auto"
                      value={meta.auto ? "true" : "false"}
                      onChange={(e) => onMetaChange(e)}
                      disabled={isViewPage}
                    >
                      <option value="false">Manual</option>
                      <option value="true">Auto</option>
                    </FormSelect>
                  </div>

                  <div className="col-span-6 mt-3">
                    <FormLabel className="text-gray-500" htmlFor="description">
                      Description
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>

                    <FormTextarea
                      id="description"
                      name="description"
                      rows={3}
                      value={meta.description}
                      onChange={(e) => onMetaChange(e)}
                      placeholder="Description"
                      disabled={isViewPage}
                    />
                  </div>
                </div>

                <div className="col-span-6 mt-3">
                  <FormLabel className="text-gray-500">
                    Additional Prices
                  </FormLabel>
                  <AdditionalPrices
                    meta={meta}
                    setMeta={setMeta}
                    isViewPage={isViewPage}
                  />
                  {!isViewPage ? (
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => addAdditionalPrice(meta, setMeta)}
                      className="text-blue-500 w-full mt-5 "
                    >
                      Add Additional Price
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}

            {step === 1 || isViewPage ? (
              <>
                <BankDetails
                  meta={meta}
                  onMetaChange={onMetaChange}
                  isViewPage={isViewPage}
                />

              </>
            ) : (
              ""
            )}

            
{isViewPage ? (
                  <>
                    <div className="title my-3 w-full col-span-6 md:col-span-6">
                      <div className=" text-2xl text-gray-600 font-bold">
                        License Front & Back
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6 p-4 border w-full border-blue-500 border-2 rounded-lg shadow-sm">
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <div className="col-span-1 md:col-span-1">
                          <ImageZoom
                            src={meta.licenseFront || ""}
                            className="w-full h-auto md:w-auto md:h-full rounded-lg"
                          />
                        </div>
                        <div className="col-span-1 md:col-span-1">
                          <ImageZoom
                            src={meta.licenseBack || ""}
                            className="w-full h-auto md:w-auto md:h-full rounded-lg"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
            {!isViewPage ? (
              <div className="col-span-6 justify-self-end flex gap-2">
                {step > 0 && (
                  <Button
                    className="bg-red-600 text-white"
                    onClick={() => stepHandler("prev")}
                  >
                    Prev
                  </Button>
                )}
                {!isLastStep ? (
                  <Button
                    className="bg-green-600 text-white"
                    onClick={() => stepHandler("next")}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    className="bg-blue-600 text-white"
                    onClick={onUpdateTransportType}
                  >
                    Update
                  </Button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </PreviewComponent>
    </>
  );
};

export default EditTransport;
