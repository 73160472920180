// @ts-nocheck
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

import { PreviewComponent } from "../../base-components/PreviewComponent";
import {
  FormInput,
  FormLabel,
  FormSelect,
  FormTextarea,
} from "../../base-components/Form";
import Button from "../../base-components/Button";
import Loading from "../../components/Loading";
import AdditionalPrices, {
  addAdditionalPrice,
} from "./Component/AdditionalPrices";
import { useDecodedToken } from "../../hooks/useDecodedToken";
import { validateNumberNotNegative } from "../../utils/helper";
import BankDetails from "./Component/BankDetails";
import { vehicleTypes } from "./vehicleTypes";
import CustomFormInput from "../../custom-components/CustomFormInput";

const CreateTransportType = () => {
  // const isViewPage = mode === 'view'
  const navigate = useNavigate();
  const [isAddingTransportType, setIsAddingTransportType] =
    useState<boolean>(false);

  const decodedToken = useDecodedToken();

  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const totalSteps = 2;

  const [meta, setMeta] = useState({
    label: "Small Size",
    value: "",
    price_per_km: 0,
    people: 0,
    bags: 0,
    seats: 0,
    ac: false,
    auto: false,
    doors: 0,
    description: "",
    additional_price: [],
    car_model: "",
    car_number: "",
    licenseFront: null,
    licenseBack: null,
    bank_details: {
      accountName: "",
      bankName: "",
      accountNumber: "",  
      iban: "",
      billingAddress: "",
      phoneNumberBank: "",
      swift_code: "",
      // admin_comission: 0,
    },
  });

  const stepHandler = (stepDirection = "prev") => {
    const { bank_details } = meta;

    if (stepDirection === "prev" && step > 0) {
      setStep((s) => s - 1);
    }

    if (step === 0) {
      if (!meta.label) {
        return toast.error("Car Type is required");
      } else if (!meta.car_model) {
        return toast.error("Car Name is required");
      } else if (!meta.car_number) {
        return toast.error("Car Registration Number is required");
      } else if (!meta.label) {
        return toast.error("Car Type is required");
      } else if (!meta.description) {
        return toast.error("Description is required");
      } else if (!meta.price_per_km) {
        return toast.error("Price Per Km is required");
      } else if (!meta.people) {
        return toast.error("People must be greater than zero");
      } else if (!meta.bags) {
        return toast.error("Luggage must be greater than zero");
      } else if (!meta.doors) {
        return toast.error("Doors must be greater than zero");
      }
    }

    // if (step === 1) {
    //   // bank details validation...
    // }
    if (stepDirection === "next" && step < totalSteps - 1) {
      setStep((s) => s + 1);
    }
  };

  console.log("meta", meta);

  const onCreateMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    field?: string
  ) => {
    const { name, value, type } = e.target;

    const nonNegativeFields = [
      "price_per_km",
      "people",
      "bags",
      // "seats",
      "doors",
    ];
    if (nonNegativeFields.includes(name)) {
      console.log("name", name);
      return validateNumberNotNegative(type, value, setMeta, name);
    }

    if (field) {
      console.log("field", field);
      return setMeta((prevMeta) => {
        return {
          ...prevMeta,
          [field]: {
            ...(prevMeta[field] || {}), // Ensure the field exists and is an object
            [name]: value,
          },
        };
      });
    }

    setMeta((prevMeta) => ({
      ...prevMeta,
      [name]: value === "true" ? true : value === "false" ? false : value,
    }));
  };

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const dataURL = reader.result;
        console.log("dataurl", dataURL);
        onCreateMetaChange({ target: { name, value: dataURL } });
      };
    }
  };

  const onAddTransportType = () => {
    if (!decodedToken?.id) return console.log("decoded token is null");
    const { bank_details } = meta;
    // if (!meta.label) {
    //   toast.error("Car Type is required");
    // } else if (!meta.car_model) {
    //   toast.error("Car Name is required");
    // } else if (!meta.car_number) {
    //   toast.error("Car Registration Number is required");
    // } else if (!meta.label) {
    //   toast.error("Car Type is required");
    // } else if (!meta.description) {
    //   toast.error("Description is required");
    // } else if (!meta.price_per_km) {
    //   toast.error("Price Per Km is required");
    // } else if (!meta.people) {
    //   toast.error("People must be greater than zero");
    // } else if (!meta.bags) {
    //   toast.error("Luggage must be greater than zero");
    // } else if (!meta.doors) {
    //   toast.error("Doors must be greater than zero");
    // }

    if (!bank_details.accountName) {
      return toast.error("Bank account name is required");
    } else if (!bank_details.bankName) {
      return toast.error("Bank name is required");
    } else if (!bank_details.accountNumber) {
      return toast.error("Bank account number is required");
    } else if (!bank_details.iban) {
      return toast.error("IBAN is required");
    } else if (!bank_details.billingAddress) {
      return toast.error("Billing address is required");
    } else if (!bank_details.phoneNumberBank) {
      return toast.error("Bank phone number is required");
    } else if (!bank_details.swift_code) {
      return toast.error("SWIFT code is required");
    } else {
      setIsAddingTransportType(true);
      meta.value = meta.label;

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/transport-type/${decodedToken?.id}`,
          meta
        )
        .then((response) => {
          if (response.status === 201) {
            toast.success("Transport type created successfully");
            setIsAddingTransportType(false);
            navigate("/transport-type");
          } else {
            toast.error("Something went wrong.");
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            toast.error("Something went wrong! Please try again later.");
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    }
  };

  const isLastStep = step === totalSteps - 1;

  return (
    <>
      {loading == true && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">Create New transport</h1>
          <div className="w-full grid grid-cols-6 gap-4 pt-6">
            {step === 0 ? (
              <>
                <div className="title  w-full col-span-6 md:col-span-6">
                  <div className=" text-2xl text-gray-600 font-bold">
                    Basic Info
                  </div>
                  <div className="mutted w-full text-xs text-mutted text-gray-400">
                    Please fill the required fields
                  </div>
                </div>
                <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-8 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
                  <div className="col-span-3 md:col-span-2">
                    <FormLabel
                      htmlFor="hotelName"
                      className="text-gray-500 flex items-center"
                    >
                      Car Type
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>

                    <FormSelect
                      id="label"
                      name="label"
                      value={meta.label}
                      onChange={(e) => onCreateMetaChange(e)}
                    >
                      {vehicleTypes.map((vehicle) => (
                        <option value={vehicle.name}>{vehicle.name}</option>
                      ))}

                      {/* <option value="#">Select Car Type</option>
                  <option value="Mid Size">Mid Size</option>
                  <option value="Large Size">Large Size</option>
                  <option value="Toyota Innova">Toyota Innova</option>
                  <option value="Hundai H1">Hundai H1</option>
                  <option value="GMC Yukon XL">GMC Yukon XL</option>
                  <option value="Coaster">Coaster</option>
                  <option value="Bus">Bus</option> */}
                    </FormSelect>
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <FormLabel
                      htmlFor="car_model"
                      className="text-gray-500 flex items-center"
                    >
                      Car Name
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="text"
                      id="car_model"
                      name="car_model"
                      value={meta.car_model}
                      onChange={(e) => onCreateMetaChange(e)}
                      placeholder="Enter Car Name"
                    />
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <FormLabel
                      htmlFor="model"
                      className="text-gray-500 flex items-center"
                    >
                      Car Registration Number
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="text"
                      id="car_number"
                      name="car_number"
                      value={meta.car_number}
                      onChange={(e) => onCreateMetaChange(e)}
                      placeholder="Enter Car Registeration Number"
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="price_per_km"
                    >
                      Price/km <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="number"
                      id="price_per_km"
                      name="price_per_km"
                      value={meta.price_per_km}
                      onChange={(e) => onCreateMetaChange(e)}
                      placeholder="Price per kilometer"
                    />
                  </div>
                  <div className="col-span-6 md:col-span-1">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="bags"
                    >
                      Luggage
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="number"
                      min={0}
                      id="bags"
                      name="bags"
                      value={meta.bags}
                      onChange={(e) => onCreateMetaChange(e)}
                      placeholder="Luggage capacity"
                    />
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="doors"
                    >
                      Doors
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="number"
                      id="doors"
                      name="doors"
                      value={meta.doors}
                      onChange={(e) => onCreateMetaChange(e)}
                      placeholder="Doors"
                      // disabled={isViewPage}
                    />
                  </div>

                  <div className="col-span-4 md:col-span-1">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="people"
                    >
                      People <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormInput
                      type="number"
                      id="people"
                      name="people"
                      value={meta.people}
                      onChange={(e) => onCreateMetaChange(e)}
                      placeholder="People"
                    />
                  </div>
                  {/* <div className="col-span-4 md:col-span-1">
                <FormLabel className="text-gray-500" htmlFor="seats">
                  Seats <span className="text-red-500 ">*</span>
                </FormLabel>
                <FormInput
                  type="number"
                  id="seats"
                  name="seats"
                  value={meta.seats}
                  onChange={(e) => onCreateMetaChange(e)}
                  placeholder="seats"
                />
              </div> */}
                  <div className="col-span-6 md:col-span-1">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="ac"
                    >
                      AC
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormSelect
                      id="ac"
                      name="ac"
                      value={meta.ac ? "true" : "false"}
                      onChange={(e) => onCreateMetaChange(e)}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </FormSelect>
                  </div>
                  <div className="col-span-6 md:col-span-1">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="auto"
                    >
                      Transmission
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>
                    <FormSelect
                      id="auto"
                      name="auto"
                      value={meta.auto ? "true" : "false"}
                      onChange={(e) => onCreateMetaChange(e)}
                      // disabled={isViewPage}
                    >
                      <option value="false">Manual</option>
                      <option value="true">Auto</option>
                    </FormSelect>
                  </div>

                  <div className="col-span-3 md:col-span-2">
                    <CustomFormInput
                      label="Licencse Number Front Image"
                      name="licenseFront"
                      type="file"
                      onChange={(e) => handleFileChange(e, "licenseFront")}
                      // disabled={isViewPage}
                      placeholder="Upload Front"
                      required
                      threeCol
                    />
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <CustomFormInput
                      label="Licencse Number Back Image"
                      name="licenseBack"
                      type="file"
                      onChange={(e) => handleFileChange(e, "licenseBack")}
                      // disabled={isViewPage}
                      placeholder="Upload Back"
                      required
                      threeCol
                    />
                  </div>

                  <div className="col-span-8 mt-1">
                    <FormLabel
                      className="text-gray-500 flex items-center"
                      htmlFor="description"
                    >
                      Description
                      <span className="text-red-500 ml-1">*</span>
                    </FormLabel>

                    <FormTextarea
                      id="description"
                      name="description"
                      rows={3}
                      value={meta.description}
                      onChange={(e) => onCreateMetaChange(e)}
                      placeholder="Description"
                    />
                  </div>
                </div>

                <div className="col-span-6 mt-3">
                  <FormLabel className="text-gray-500">
                    Additional Prices
                  </FormLabel>
                  <AdditionalPrices meta={meta} setMeta={setMeta} />
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => addAdditionalPrice(meta, setMeta)}
                    className="text-blue-500 w-full mt-5 "
                  >
                    Add Additional Price
                  </p>
                  {/* <Button
                onClick={addAdditionalPrice}
                className="bg-blue-500 text-white"
              >
                Add Additional Price
              </Button> */}
                </div>
              </>
            ) : (
              ""
            )}
            {step === 1 ? (
              <BankDetails
                meta={meta}
                onMetaChange={onCreateMetaChange}
                isViewPage={false}
              />
            ) : (
              ""
            )}

            <div className="col-span-6 justify-self-end flex gap-2">
              {step > 0 && (
                <Button
                  className="bg-red-600 text-white"
                  onClick={() => stepHandler("prev")}
                >
                  Prev
                </Button>
              )}
              {!isLastStep ? (
                <Button
                  className="bg-green-600 text-white"
                  onClick={() => stepHandler("next")}
                >
                  Next
                </Button>
              ) : (
                <Button
                  className="bg-blue-600 text-white"
                  onClick={onAddTransportType}
                >
                  Submit
                </Button>
              )}
            </div>

            {/* <div className="col-span-6 justify-self-end">
              <Button
                className="bg-green-600 text-white"
                onClick={onAddTransportType}
              >
                Add Transport type
              </Button>
            </div> */}
          </div>
        </div>
      </PreviewComponent>
    </>
  );
};

export default CreateTransportType;
