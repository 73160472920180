// @ts-nocheck

import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

import { PreviewComponent } from "../../base-components/PreviewComponent";
import { FormLabel, FormSelect } from "../../base-components/Form";
import Dropzone, { DropzoneElement } from "../../base-components/Dropzone";
import Button from "../../base-components/Button";
import Loading from "../../components/Loading";
import CustomFormInput from "../../custom-components/CustomFormInput";
import ImageZoom from "../../base-components/ImageZoom";
import { useDecodedToken } from "../../hooks/useDecodedToken";
import Dropdown from "../../custom-components/Dropdown";
import { Image } from "antd";
import { vehicleTypes } from "../TransportType/vehicleTypes";

const TransportDriverForm = ({ mode }: any) => {
  const navigate = useNavigate();

  const location = useLocation();
  const isViewPage = mode === "view";

  const [loading, setLoading] = useState<boolean>(false);
  const dropzoneRef = useRef<DropzoneElement | any>();

  const [meta, setMeta] = useState({
    name: "",
    License_no: "",
    phonenumber: "",
    address: "",
    cnic: "",
    image: null,
    email: null,
    transmission_type: "Manual", // Manual, Automatic
    vehicle_type: "Bus", // "Bus", "Van", "Minivan"
    licenseFront: null,
    licenseBack: null,
  });

  const decodedToken: any = useDecodedToken();
  useEffect(() => {
    if (mode === "edit" || mode === "view") {
      setMeta({
        ...location.state,
        image: location.state.image || null,
      });
    }
  }, [mode, location.state]);

  // useEffect(() => {
  if (dropzoneRef.current) {
    dropzoneRef.current.dropzone.on("addedfile", (file: any) => {
      // Limit the number of files to 1
      if (dropzoneRef.current.dropzone.files.length > 1) {
        dropzoneRef.current.dropzone.removeFile(
          dropzoneRef.current.dropzone.files[0]
        );
      }
      setMeta({
        ...meta,
        image: file,
      });
    });
  }
  // }, []);

  let title;
  if (mode === "add") {
    title = "Create New transport driver";
  } else if (mode === "edit") {
    title = "Update transport driver";
  } else {
    title = meta.name;
  }
  console.log("meta", meta);
  const onMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setMeta((prevMeta) => ({
      ...prevMeta,
      [name]: value,
    }));
  };

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const dataURL = reader.result;
        console.log("dataurl", dataURL);
        onMetaChange({ target: { name, value: dataURL } });
      };
    }
  };

  const validateFields = (fields: any) => {
    const errors: any = {};
    if (!fields.name) {
      //@ts-ignore
      errors.name = "Name is required";
    }
    if (!fields.License_no) {
      errors.License_no = "License number is required";
    }
    if (!fields.phonenumber) {
      errors.phonenumber = "Phone number is required";
    }
    if (!fields.address) {
      errors.address = "Address is required";
    }
    if (!fields.cnic) {
      errors.cnic = "National ID Number is required";
    }
    if (!fields.licenseFront) {
      errors.licenseFront = "Upload Front of license";
    }
    if (!fields.licenseBack) {
      errors.licenseBack = "Upload Back of license";
    }
    return errors;
  };

  const params = useParams();

  const onButtonClick = () => {
    setLoading(true);
    if (!decodedToken) return console.log("decoded token is null");

    const errors = validateFields(meta);
    if (Object.keys(errors).length > 0) {
      console.error("Validation errors:", errors);
      Object.values(errors).forEach((error: any) => toast.error(error));
      setLoading(false);
      return;
    }

    // to handle actions dynamically as per mode
    const isAdding = mode === "add";

    axios[isAdding ? "post" : "put"](
      `${process.env.REACT_APP_BACKEND_URL}/${
        isAdding ? `drivers/${decodedToken?.id}` : "drivers/" + params?.id
      }`,
      meta
    )
      .then((response) => {
        console.log("response", response);
        const checkStatus = isAdding ? 201 : 200;
        if (response.status === checkStatus) {
          toast.success(
            `Transport driver ${isAdding ? "created" : "updated"} successfully`
          );
          setLoading(false);
          navigate("/transport-drivers");
        } else {
          toast.error("Something went wrong.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error:-", error);
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };

  return (
    <>
      {loading == true && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">{title}</h1>
          <div className="w-full grid grid-cols-6 gap-4 pt-6">
            <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3  border-blue-500  rounded-lg shadow-sm">
              {/* <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="hotelName">
                  Name
                </FormLabel>
                <FormInput
                  type="text"
                  id="label"
                  name="label"
                  value={meta.label}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="add label name"
                />
              </div> */}
              <div className="col-span-6 md:col-span-2">
                <CustomFormInput
                  label="Name"
                  name="name"
                  type="text"
                  value={meta.name}
                  onChange={onMetaChange}
                  placeholder="Add driver name"
                  disabled={isViewPage}
                  required
                />
              </div>
              <div className="col-span-6 md:col-span-2">
                <CustomFormInput
                  label="License No."
                  name="License_no"
                  type="text"
                  value={meta.License_no}
                  onChange={onMetaChange}
                  placeholder="Add driver license no."
                  disabled={isViewPage}
                  required
                />
              </div>
              <div className="col-span-6 md:col-span-2">
                <CustomFormInput
                  label="Phone Number"
                  name="phonenumber"
                  type="text"
                  value={meta.phonenumber}
                  onChange={onMetaChange}
                  placeholder="Add driver phone number"
                  disabled={isViewPage}
                  required
                />
              </div>
              <div className="col-span-6 md:col-span-2">
                <CustomFormInput
                  label="Email"
                  name="email"
                  type="text"
                  value={meta.email}
                  onChange={onMetaChange}
                  placeholder="Add driver email"
                  disabled={isViewPage}
                  required
                />
              </div>
              <div className="col-span-6 md:col-span-2">
                <CustomFormInput
                  label="Address"
                  name="address"
                  type="text"
                  value={meta.address}
                  onChange={onMetaChange}
                  placeholder="Add driver address"
                  disabled={isViewPage}
                  required
                />
              </div>
              <div className="col-span-6 md:col-span-2">
                <CustomFormInput
                  label="National ID Number"
                  name="cnic"
                  type="text"
                  value={meta.cnic}
                  onChange={onMetaChange}
                  placeholder="Add driver National ID Number"
                  disabled={isViewPage}
                  required
                />
              </div>
              <div className="col-span-6 md:col-span-2">
                <FormLabel
                  className="text-gray-500 flex items-center"
                  htmlFor="transmission_type"
                >
                  Transmission Type
                  <span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormSelect
                  id="transmission_type"
                  name="transmission_type"
                  value={meta.transmission_type}
                  onChange={onMetaChange}
                >
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </FormSelect>
              </div>
              <div className="col-span-6 md:col-span-2">
                <FormLabel
                  className="text-gray-500 flex items-center"
                  htmlFor="vehicle_type"
                >
                  Vehicle Type
                  <span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormSelect
                  id="vehicle_type"
                  name="vehicle_type"
                  value={meta.vehicle_type}
                  onChange={onMetaChange}
                >
                  {vehicleTypes.map((vehicle) => (
                    <option value={vehicle.name}>{vehicle.name}</option>
                  ))}
                  {/* <option value="Bus">Bus</option>
                  <option value="Van">Van</option>
                  <option value="Minivan">Minivan</option> */}
                </FormSelect>
              </div>

              {!isViewPage ? (
                  <>
                  <div className="col-span-3 md:col-span-2">
                    <CustomFormInput
                      label="Licencse Number Front Image"
                      name="licenseFront"
                      type="file"
                      onChange={(e) => handleFileChange(e, "licenseFront")}
                      disabled={isViewPage}
                      placeholder="Upload Front"
                      required
                      threeCol
                    />
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <CustomFormInput
                      label="Licencse Number Back Image"
                      name="licenseBack"
                      type="file"
                      onChange={(e) => handleFileChange(e, "licenseBack")}
                      disabled={isViewPage}
                      placeholder="Upload Back"
                      required
                      threeCol
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              {/* <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="status">
                  status
                </FormLabel>
                <FormSelect
                  id="status"
                  name="status"
                  value={meta.status}
                  onChange={(e) => onMetaChange(e)}
                  disabled={isViewPage}
                >
                  <option value="active">Active</option>
                  <option value="inactive">In Active</option>
                </FormSelect>
              </div> */}
            </div>

            {/* (
                <div className="flex ">
                  <Image src={meta.licenseFront} />
                  <Image src={meta.licenseBack} />
                </div>
              ) */}

            {isViewPage ? (
              <>
                <div className="title my-3 w-full col-span-6 md:col-span-6">
                  <div className=" text-2xl text-gray-600 font-bold">
                    License Front & Back
                  </div>
                </div>
                <div className="col-span-6 md:col-span-6 p-4 border w-full border-blue-500 border-2 rounded-lg shadow-sm">
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="col-span-1 md:col-span-1">
                      <ImageZoom
                        src={meta.licenseFront || ""}
                        className="w-full h-auto md:w-auto md:h-full rounded-lg"
                      />
                    </div>
                    <div className="col-span-1 md:col-span-1">
                      <ImageZoom
                        src={meta.licenseBack || ""}
                        className="w-full h-auto md:w-auto md:h-full rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {isViewPage ? (
              <>
                <div className="title my-3 w-full col-span-6 md:col-span-6">
                  <div className=" text-2xl text-gray-600 font-bold">
                    Upload Driver Image
                  </div>
                </div>
                <div className="col-span-6 md:col-span-6 p-4 border w-full border-blue-500 border-2 rounded-lg shadow-sm">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div className="col-span-1 md:col-span-1">
                      <ImageZoom
                        src={meta.image || ""}
                        className="w-full h-auto md:w-auto md:h-full rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {mode === "add" ? (
              <div className="col-span-6">
                <FormLabel className="text-gray-500" htmlFor="image">
                  Upload Driver Image
                </FormLabel>

                <Dropzone
                  id="image"
                  getRef={(el) => {
                    dropzoneRef.current = el;
                  }}
                  options={{
                    url: "https://httpbin.org/post",
                    thumbnailWidth: 150,
                    maxFilesize: 0.5,
                    maxFiles: 1,
                    headers: { "My-Awesome-Header": "header value" },
                  }}
                  className="dropzone"
                >
                  <div className="text-lg font-medium">
                    Drop files here or click to upload.
                  </div>
                  <div className="text-gray-600">
                    Note: Ensure your files are in JPEG or PNG format and under
                    0.5 MB.
                  </div>
                </Dropzone>
              </div>
            ) : (
              ""
            )}

            {!isViewPage ? (
              <div className="col-span-6 justify-self-end">
                <Button
                  className="bg-green-600 text-white"
                  onClick={() => {
                    onButtonClick();
                  }}
                >
                  {mode === "add" ? "Add Driver" : "Update Driver"}
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </PreviewComponent>
    </>
  );
};

export default TransportDriverForm;
