export const vehicleTypes = [
  { id: 1, name: "Small Size", value: "small" },
  { id: 2, name: "Mid Size", value: "mid" },
  { id: 3, name: "Large Size", value: "large" },
  { id: 4, name: "Minivan", value: "minivan" },
  { id: 5, name: "Hiace", value: "hiace" },
  { id: 6, name: "Coaster", value: "coaster" },
  { id: 7, name: "GMC", value: "gmc" },
  { id: 8, name: "Bus", value: "bus" },
];
