import { useRoutes } from "react-router-dom";
import SideMenu from "../layouts/SideMenu";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ErrorPage from "../pages/ErrorPage";
import CreateTransportType from "../pages/TransportType/CreateTransportType";
import TransportType from "../pages/TransportType/TransportType";
import EditTransport from "../pages/TransportType/EditTransport";
import TransportDriver from "../pages/TransportDriver";
import TransportDriverForm from "../pages/TransportDriver/TransportDriverForm";
import TransportBookings from "../pages/Bookings/TransportBookings";
import TransportBookingDetails from "../pages/Bookings/TransportBookings/TransportBookingDetails";
import CarRental from "../pages/CarRental";
import CarRentalForm from "../pages/CarRental/CarRentalForm";
import CarCategory from "../pages/CarRental/CarCategory";
import CategoryForm from "../pages/CarRental/CarCategory/CategoryForm";
import CarRentalBookings from "../pages/Bookings/CarRentalBookings";
import CarRentalBookingDetails from "../pages/Bookings/CarRentalBookings/CarRentalBookingDetails";
import CarRentalPayments from "../pages/Finance/CarRentalFinance/payments";
import TransportPayments from "../pages/Finance/TransportFinance/payments";
import BookingDetails from "../pages/Finance/TransportFinance/BookingDetails";
import { jwtDecode } from "jwt-decode";
import CarRentalRecieveFromAdmin from "../pages/Finance/CarRentalFinance/RecieveFromAdmin";
import ReceiveFromAdmin from "../pages/Finance/TransportFinance/RecieveFromAdmin";
import CarRentalDashoboard from "../pages/CarRental/Dashboard";
import TransportDashoboard from "../pages/TransportType/Dashboard";
import TermsAndConditions from "../pages/legal-docs/TermsAndConditions";
import PrivacyPolicy from "../pages/legal-docs/PrivacyPolicy";

function Router() {
  let AuthToken = localStorage.getItem("doyoofToken");
  const decodedToken: any = AuthToken
    ? jwtDecode(AuthToken?.split(" ")[1])
    : [];
  let role = decodedToken.role;

  const transportRoutes = [
    {
      path: "/",
      element: <SideMenu />,
      children: [
        { path: "/", element: <TransportDashoboard /> },
        { path: "/transport-type", element: <TransportType /> },
        { path: "/create-transport", element: <CreateTransportType /> },
        { path: "/edit-transport/:id", element: <EditTransport /> },
        { path: "/view-transport/:id", element: <EditTransport /> },
        { path: "/transport-drivers", element: <TransportDriver /> },
        { path: "/create-driver", element: <TransportDriverForm mode="add" /> },
        {
          path: "/edit-driver/:id",
          element: <TransportDriverForm mode="edit" />,
        },
        {
          path: "/view-driver/:id",
          element: <TransportDriverForm mode="view" />,
        },
        { path: "/transport_bookings", element: <TransportBookings /> },
        {
          path: "/view-transport-booking-details/:booking_id",
          element: <TransportBookingDetails />,
        },
        { path: "/payments", element: <TransportPayments /> },
        {
          path: "/view-transport-bookings/:bookingId",
          element: <BookingDetails />,
        },
        { path: "/receive_from_admin", element: <ReceiveFromAdmin /> },
      ],
    },
  ];

  const carRentalRoutes = [
    {
      path: "/",
      element: <SideMenu />,
      children: [
        // { path: "/", element: <Dashboard /> },
        { path: "/", element: <CarRentalDashoboard /> },
        { path: "/car-rental", element: <CarRental /> },
        { path: "/add-car", element: <CarRentalForm mode="add" /> },
        { path: "/view-car/:carId", element: <CarRentalForm mode="view" /> },
        { path: "/edit-car/:carId", element: <CarRentalForm mode="edit" /> },
        { path: "/car-category", element: <CarCategory /> },
        { path: "/add-category", element: <CategoryForm mode="add" /> },
        {
          path: "/view-category/:categoryId",
          element: <CategoryForm mode="view" />,
        },
        { path: "/car_rental-bookings", element: <CarRentalBookings /> },
        {
          path: "/view-car_rental-booking-details/:booking_id",
          element: <CarRentalBookingDetails />,
        },
        { path: "/payments", element: <CarRentalPayments /> },
        { path: "/receive_from_admin", element: <CarRentalRecieveFromAdmin /> },
      ],
    },
  ];

  const commonRoutes: any = [
    { path: "/login", element: <Login /> },
    {
      path: "/terms-and-conditions",
      element: <TermsAndConditions />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    { path: "/register", element: <Register /> },
    { path: "/error-page", element: <ErrorPage /> },
    { path: "*", element: <ErrorPage /> },
  ];

  const routes =
    role === "CarRental"
      ? carRentalRoutes.concat(commonRoutes)
      : transportRoutes.concat(commonRoutes);

  return useRoutes(routes);
}

export default Router;

// import { useRoutes } from "react-router-dom";
// import SideMenu from "../layouts/SideMenu";
// import Dashboard from "../pages/Dashboard";
// import Login from "../pages/Login";
// import Register from "../pages/Register";
// import ErrorPage from "../pages/ErrorPage";
// import CreateTransportType from "../pages/TransportType/CreateTransportType";
// import TransportType from "../pages/TransportType/TransportType";
// import EditTransport from "../pages/TransportType/EditTransport";
// import TransportDriver from "../pages/TransportDriver";
// import TransportDriverForm from "../pages/TransportDriver/TransportDriverForm";
// import TransportBookings from "../pages/Bookings/TransportBookings";
// import TransportBookingDetails from "../pages/Bookings/TransportBookings/TransportBookingDetails";
// import CarRental from "../pages/CarRental";
// import CarRentalForm from "../pages/CarRental/CarRentalForm";
// import CarCategory from "../pages/CarRental/CarCategory";
// import CategoryForm from "../pages/CarRental/CarCategory/CategoryForm";
// import CarRentalBookings from "../pages/Bookings/CarRentalBookings";
// import CarRentalBookingDetails from "../pages/Bookings/CarRentalBookings/CarRentalBookingDetails";
// import CarRentalPayments from "../pages/Finance/CarRentalFinance/payments";
// import TransportPayments from "../pages/Finance/TransportFinance/payments";
// import { jwtDecode } from "jwt-decode";
// import BookingDetails from "../pages/Finance/TransportFinance/BookingDetails";

// function Router() {
//   console.log("hell");
//   let AuthToken = localStorage.getItem("doyoofToken");
//   const decodedToken = AuthToken ? jwtDecode(AuthToken?.split(" ")[1]) : "";
//   let role = decodedToken?.role;
//   console.log("decoded Token", decodedToken);

//   const routes = [
//     {
//       path: "/",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/",
//           element: <Dashboard />,
//         },
//         {
//           path: "/transport-type",
//           element: <TransportType />,
//         },
//       ],
//     },
//     {
//       path: "/create-transport",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/create-transport",
//           element: <CreateTransportType />,
//         },
//       ],
//     },
//     {
//       path: "/edit-transport/:id",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/edit-transport/:id",
//           element: <EditTransport />,
//         },
//       ],
//     },
//     {
//       path: "/view-transport/:id",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/view-transport/:id",
//           element: <EditTransport />,
//         },
//       ],
//     },
//     {
//       path: "/transport-drivers",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/transport-drivers",
//           element: <TransportDriver />,
//         },
//       ],
//     },
//     {
//       path: "/create-driver",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/create-driver",
//           element: <TransportDriverForm mode="add" />,
//         },
//       ],
//     },
//     {
//       path: "/edit-driver/:id",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/edit-driver/:id",
//           element: <TransportDriverForm mode="edit" />,
//         },
//       ],
//     },
//     {
//       path: "/view-driver/:id",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/view-driver/:id",
//           element: <TransportDriverForm mode="view" />,
//         },
//       ],
//     },
//     {
//       path: "/transport_bookings",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/transport_bookings",
//           element: <TransportBookings />,
//         },
//       ],
//     },
//     {
//       path: "/view-transport-booking-details",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/view-transport-booking-details/:booking_id",
//           element: <TransportBookingDetails />,
//         },
//       ],
//     },

//     // Car rental
//     {
//       path: "/car-rental",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/car-rental",
//           element: <CarRental />,
//         },
//       ],
//     },
//     {
//       path: "/add-car",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/add-car",
//           element: <CarRentalForm mode="add" />,
//         },
//       ],
//     },
//     {
//       path: "/view-car/:carId",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/view-car/:carId",
//           element: <CarRentalForm mode="view" />,
//         },
//       ],
//     },
//     {
//       path: "/edit-car/:carId",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/edit-car/:carId",
//           element: <CarRentalForm mode="edit" />,
//         },
//       ],
//     },

//     {
//       path: "/car-category",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/car-category",
//           element: <CarCategory />,
//         },
//       ],
//     },
//     {
//       path: "/add-category",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/add-category",
//           element: <CategoryForm mode="add" />,
//         },
//       ],
//     },
//     {
//       path: "/car_rental-bookings",
//       element: <SideMenu />,
//       children: [
//         { path: "/car_rental-bookings", element: <CarRentalBookings /> },
//       ],
//     },
//     {
//       path: "/view-car_rental-booking-details/:booking_id",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/view-car_rental-booking-details/:booking_id",
//           element: <CarRentalBookingDetails />,
//         },
//       ],
//     },
//     {
//       path: "/payments",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/payments",
//           element:
//             role === "CarRental" ? (
//               <CarRentalPayments />
//             ) : (
//               <TransportPayments />
//             ),
//         },
//       ],
//     },
//     {
//       path: "/view-transport-bookings",
//       element: <SideMenu />,
//       children: [
//         {
//           path: "/view-transport-bookings/:bookingId",
//           element: <BookingDetails />,
//         },
//       ],
//     },

//     {
//       path: "/login",
//       element: <Login />,
//     },
//     {
//       path: "/register",
//       element: <Register />,
//     },
//     {
//       path: "/error-page",
//       element: <ErrorPage />,
//     },
//     {
//       path: "*",
//       element: <ErrorPage />,
//     },
//   ];

//   return useRoutes(routes);
// }

// export default Router;
